<template>
  <div
    :id="`region-item-${index}`" class="fill-height d-flex flex-row align-center pa-2 rounded-lg competition-item"
    @click="$emit('onSelectRegion', item.id)"
  >
    <div class="d-flex flex-row align-center justify-space-between" style="width: 66%;">
      <span v-if="!clicked" class="ml-2" style="user-select: none;" v-text="item.name" />
    </div>
    <div class="d-flex flex-column justify-center ml-auto fill-height">
      <v-dialog v-model="clicked" max-width="520px" fullscreen transition="fade-transition" overlay-color="black" overlay-opacity="1">
        <div data-fullscreen class="vw-100 h-100">
          <div class="d-flex pa-2 rounded-lg w-100 competition-item__zone-edit" :style="stylesDialog.editZone">
            <v-text-field
              v-if="clicked" v-model="form.name" :height="34" :error-messages="getFieldErrors('name')"
              color="primary" clearable class="px-2" :autofocus="true" @blur="$v.form.name.$touch()"
            />
            <v-btn icon color="primary" @click="clicked= false; $emit('closeDialog')">
              <v-icon v-text="'close'" />
            </v-btn>
          </div>
          <v-btn
            :disabled="!item.id" class="mx-2 d-none d-md-inline-flex" fab dark small color="primary" :width="44" :height="44"
            :style="stylesDialog.deleteButton" @click="$emit('onDelete', item.id)"
          >
            <v-icon dark v-text="'delete_outline'" />
          </v-btn>
          <v-btn
            color="primary" class="w-100" :max-width="241" :style="stylesDialog.saveButton"
            @click="save" v-text="$t('common.save')"
          />
        </div>
      </v-dialog>
      <v-menu v-if="item.name">
        <template #activator="{ on, attrs }">
          <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="canDelete" @click="$emit('onDelete', item.id)">
            <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="clicked = true">
            <v-list-item-title>{{ $t('common.modify') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import itemMixin from './itemMixin'

export default {
  name: 'CompetitionRegionItem',
  mixins: [itemMixin],
  props: {
    canDelete: { type: Boolean, required: true },
  },
  created() {
    this.$nextTick(() => {
      this.currentElementPosition = document.getElementById(`region-item-${this.index}`).getBoundingClientRect()
    })
  },
}
</script>
<style lang="scss" scoped>
@import './itemStyle';
</style>
